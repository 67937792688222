export const networkTypes = {
  CEKICI: 1,
  ARAC_KIRALAMA: 2,
  TESISATCI: 3,
  ELEKTRIKCI: 4,
  CAMCI: 5,
  CILINGIR_KONUT: 6,
  CILINGIR_OTO: 7,
  AMBULANS: 8,
  OTEL: 9,
  OTO_TAMIR_SERVISI: 10,
  TIBBI_DANISMANLIK: 11,
  HUKUKI_DANISMANLIK: 12,
  DIGER: 13
}

export const claimTypes = {
  ANAHTAR_KAYBI_ARIZASI_ICERIDE_UNUTULMASI: 1,
  ARAC_YANMASI: 2,
  ARIZA: 3,
  CALINIP_BULUNMA: 4,
  DIS_CHECK_UP: 5,
  TIBBI_HIZMETLER: 6,
  GOZ_CHECKUP: 7,
  OTO_DISI_SEL_SU_HASARI: 8,
  HUKUKI_DANISMANLIK: 9,
  KAZA: 10,
  LASTIK_HASARI: 11,
  YAKIT_BITMESI: 12,
  YANLIS_YAKIT: 13,
  CAM_KIRILMASI: 14,
  DEPREM: 15,
  ELEKTRIK_HASARI: 16,
  GUVENLIK_GOREVLISI: 17,
  HALI_YIKAME: 18,
  KAPI_KILIT: 19,
  KOMBI_KLIMA_BAKIM: 20,
  SEL_SU: 21,
  TEMIZLIK_GOREVLISI: 22,
  TESISAT_HASARI: 23,
  AMBULANS: 24,
  YARALANMA: 25,
  OTO_DISI_YANGIN: 26,
  BILGI: 27,
  TALEP_SIKAYET: 28
}

export const serviceTypeTow = {
  AKU_TAKVIYESI: 1,
  BENZIN_TAKVIYESI: 6,
  CEKICI: 10,
  LASTIK_DEGISIM_OLAY_YERI: 27
}

export const serviceTypeRentalCar = {
  KIRALIK_ARAC: 24,
  YOLA_DEVAM_ARACI: 35
}

export const vehicleTypeCrane = {
  BINEK: 1,
  HAFIF_TICARI_SASE: 2
}

export const vehicleTypeExtraLong = {
  HAFIF_TICARI_UZUN_SASE: 3
}

/* eslint-disable spellcheck/spell-checker */

export const fileServiceStatus = {
  CANCELED: 'canceled', // 'Hizmet İptal Edildi',
  CLOSED: 'closed', // Hizmet Kapalı
  OPENED: 'opened' // Açıldı
}

export const vehicleTypes = {
  BINEK: 1,
  HAFIF_TICARI_KISA_SASE: 2,
  HAFIF_TICARI_UZUN_SASE: 3,
  AGIR_TICARI: 4,
  OZEL_MAKSATLI: 5,
  MOTOSIKLET: 6
}
