<template>
  <f-page :title="$t('labels.index')">
    <f-form @submit="refreshNetworkDatatable">
      <f-card>
        <f-row>
          <f-col md="6">
            <f-input v-model="search.no" :label="$t('labels.fileNo')" />
          </f-col>
          <f-col md="6">
            <f-input v-model="search.plateNo" :label="$t('labels.plate')" />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.networkAssignedStartDate"
              :label="$t('labels.serviceAssignmentDateRange')"
              type="date"
            />
          </f-col>
          <f-col md="6">
            <f-input
              v-model="search.networkAssignedEndDate"
              inline
              type="date"
            />
          </f-col>
          <f-col md="12">
            <f-select
              ref="isActiveSwitch"
              v-model="search.serviceStatus"
              :label="$t('labels.serviceStatus')"
              :options="fileServiceStatusList"
              value-type="string"
            />
          </f-col>
        </f-row>
        <template v-slot:footer>
          <f-button
            class="float-right"
            variant="primary"
            :label="$t('labels.search')"
            icon="search"
            type="submit"
          />
          <f-button
            class="float-right mr-2"
            variant="light"
            icon="eraser"
            :label="$t('labels.reset')"
            @click="clearSearch"
          />
        </template>
      </f-card>
    </f-form>
    <f-card>
      <f-datatable
        ref="networkDatatable"
        :columns="networkSearchColumns"
        data-url="/networkApp/files"
        :query-params="search"
        :features="{
          serverSidePagination: true,
          create: false
        }"
        :actions="[
          {
            name: 'goToFileDetail',
            icon: 'share-square-o',
            title: $t('labels.goToFile')
          }
        ]"
        @action="onClickAction"
      >
      </f-datatable>
    </f-card>
  </f-page>
</template>
<script>
import { networkSearchColumns } from '@/data/columns'
import { mapGetters } from 'vuex'
import { fileServiceStatusList } from '@/data/lists'
export default {
  data() {
    return {
      search: {},
      networkSearchColumns,
      fileServiceStatusList
    }
  },
  computed: { ...mapGetters(['user', 'isAuthenticated']) },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    refreshNetworkDatatable() {
      this.$refs.networkDatatable.refresh()
    },
    clearSearch() {
      this.search = {}
      // eslint-disable-next-line spellcheck/spell-checker
      this.$refs.isActiveSwitch.setCheckboxValue(false)
    },
    onClickAction(row, name) {
      if (name === 'goToFileDetail') {
        this.$router.push({
          name: 'fileDetail',
          params: row
        })
      }
    }
  }
}
</script>
