import Vue from '../main'
import { getDateTime } from '@/utils'
const $t = str => Vue.$i18n.t(str)
export const networkSearchColumns = [
  { field: 'no', title: $t('labels.fileNo') },
  {
    field: 'fileService.networkAssignedDate',
    title: $t('labels.dateTime'),
    formatter: value => getDateTime(value, 'dd/MM/yyyy HH:mm')
  },
  { field: 'fileVehicle.plateNo', title: $t('labels.plate') },
  {
    field: 'fileVehicle.vehicleModel.vehicleBrand.name',
    title: $t('labels.brand')
  },
  { field: 'fileVehicle.vehicleModel.name', title: $t('labels.model') },
  {
    field: 'fileService.status',
    title: $t('labels.serviceStatus'),
    formatter: value => $t(`labels.${value}`)
  },
  { field: 'fileService.realPrice', title: $t('labels.realizePayment') }
]

export const fileAttachmentColumns = [
  {
    field: 'name',
    title: $t('labels.attachmentTitle'),
    formatter: (value, row) => {
      // eslint-disable-next-line spellcheck/spell-checker
      return `<a href="${row.url}" target="_blank">${value}</a>`
    }
  },
  {
    field: 'createdAt',
    title: $t('labels.uploadedDate'),
    formatter: value => getDateTime(value, 'dd/MM/yyyy HH:mm')
  }
]

export const vehicleRegistrationFileColumns = [
  {
    field: 'name',
    title: $t('labels.attachmentTitle'),
    formatter: (value, row) => {
      // eslint-disable-next-line spellcheck/spell-checker
      return `<a href="${row.url}" target="_blank">${value}</a>`
    }
  },
  {
    field: 'createdAt',
    title: $t('labels.dateTime'),
    formatter: value => getDateTime(value, 'dd/MM/yyyy HH:mm')
  }
]
