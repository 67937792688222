import { fileServiceStatus } from '@/data/enums'
import i18n from '@/plugins/i18n'

export const fileServiceStatusList = [
  {
    id: fileServiceStatus.OPENED,
    name: i18n.t('labels.opened')
  },
  {
    id: fileServiceStatus.CLOSED,
    name: i18n.t('labels.closed')
  },
  {
    id: fileServiceStatus.CANCELED,
    name: i18n.t('labels.serviceCanceled')
  }
]
